/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #011e41;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(1, 30, 65, 0.38);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 30, 65, 0.38);
}

.mat-blue {
  color: #011e41;
}

.mat-blue:disabled {
  color: #263446;
}

.mat-form-field-wrapper {
  height: 35px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  width: 200px;
  height: 35px !important;
  align-items: safe center !important;
}

.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-icon-button {
  bottom: 0.35em;
  right: 0.35;
}

.mat-form-field-ripple {
  background-color: #011e41 !important;
}

.mat-input-element,
.mat-select-value {
  position: relative;
  bottom: 0.5em;
}

@supports (-moz-appearance: none) {
  .mat-form-field-wrapper {
    margin-top: 0.5em;
  }
  .mat-input-element,
  .mat-select-value {
    position: relative;
    bottom: 1em;
  }
  .mat-form-field:not(.mat-form-field-appearance-legacy)
    .mat-form-field-suffix
    .mat-icon-button {
    bottom: 0.6em;
    right: 0.35;
  }
  .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
    transform: translateY(-100%) !important;
  }
}

.spinner-container,
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #011e41;
}

.w-75 {
  width: 75px;
}

.w-100 {
  width: 100px;
}

.w-125 {
  width: 125px;
}

.w-150 {
  width: 150px;
}

.w-175 {
  width: 175px;
}

.w-200 {
  width: 200px;
}

.th-center {
  text-align: center;
}
.th-left {
  text-align: left;
}
.th-right {
  text-align: right;
}

.td-center {
  text-align: center;
  align-items: center;
}
.td-left {
  text-align: left;
  align-items: center;
}
.td-right {
  text-align: right;
  align-items: center;
}

/*th.mat-header-cell {
  text-align: center;
}*/

.grid-tile-content {
  display: flex;
  position: absolute;
  left: 5px;
  top: 0px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.record-label-title {
  font-size: medium;
  font-weight: 500;
}

.record-label-content {
  padding-left: 5px;
}

.expire-icon {
  color: rgba(238, 115, 0, 0.884);
  cursor: pointer;
}

mat-panel-title {
  align-items: center;
}

mat-expansion-panel-header mat-icon {
  color: rgba(1, 30, 65, 0.54);
  margin-right: 5px;
}

.gridList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  align-items: flex-start;
}

.gridElem {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/*.mat-form-field:not(.mat-form-field-has-label) .mat-form-field-infix {
  border-top-width: 0;
}*/

.page-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
}

.spacer {
  flex: 1 1 auto;
}

.scrollable-spacer {
  flex: 1 1 auto;
  overflow: clip;
}

.page-title {
  font-size: 2em;
  font-weight: 600;
}

.action-button {
  margin-right: 0.5em !important;
  position: sticky !important;
  top: 0 !important;
}
